@use 'sds' as *;

/* You can add global styles to this file, and also import other style files */
html {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: $font-size-default;
  line-height: 22px;
  color: $color-darkgray-3;
  height: 100%;
}

body {
  background-color: $color-white;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: $font-size-default;
  color: $color-darkgray-3;
  text-align: left;
  line-height: 22px;
  margin: 0px;
  height: 100%;
}

h4 {
  margin-top: 14px;
  margin-bottom: 7px;
  color: $color-darkgray-3;
}
