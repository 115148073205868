@use 'sds/breakpoints' as *;
@use 'sds/colors' as *;
@use 'primeng-theme' as *;

@use 'resources/primeng.min';
@use 'material-icons/iconfont/material-icons';

// ================= TABLE

p-table {
  .p-datatable-scrollable-header {
    overflow: visible; // to make drop-downs of multiselect work
  }
  .p-datatable table {
    color: $color-text-light;
  }

  &.full-screen > div.p-datatable {
    height: 100%;
    > div.p-datatable-wrapper {
      height: 100%;
      > table {
        height: 100%;
      }
    }
  }

  thead {
    font-weight: bold;
    background-color: $color-background-3;
    text-align: left;
    & > tr {
      & > th {
        padding: 12px;
        &.thin-header {
          padding: 0.2em 12px;

          .p-multiselect {
            height: auto;
            .p-multiselect-panel {
              padding-top: 0em;
            }
            .p-multiselect-label-container {
              padding-left: 0.5em;
              .p-multiselect-label {
                padding-top: 0em;
                padding-bottom: 0em;
              }
            }

            .p-checkbox .p-checkbox-box {
              width: 14px;
              height: 14px;
              border: 1px solid $color-outline;
              border-radius: 0px;

              .p-checkbox-icon {
                font-size: 14px;
                font-weight: bold;
              }
            }

            .p-multiselect-items .p-multiselect-item {
              padding: 2px 10px;
              height: auto;

              .p-checkbox {
                padding: 0 6px 0 0;
              }

              &.p-highlight {
                padding-left: 4px;
              }
            }
          }
        }
        &.large-header {
          .p-multiselect {
            .p-multiselect-panel {
              padding-top: 0em;
              height: auto;
            }
            .p-multiselect-label-container {
              padding-left: 0.5em;
              .p-multiselect-label {
                padding-top: 0em;
                padding-bottom: 0em;
              }
            }

            .p-checkbox .p-checkbox-box {
              width: 14px;
              height: 14px;
              border: 1px solid $color-outline;
              border-radius: 0px;

              .p-checkbox-icon {
                font-size: 14px;
                font-weight: bold;
              }
            }

            .p-multiselect-items .p-multiselect-item {
              padding: 2px 10px;
              height: auto;

              .p-checkbox {
                padding: 0 6px 0 0;
              }

              &.p-highlight {
                padding-left: 4px;
              }
            }
          }
        }
        &.p-sortable-column {
          // This is an improved ellipsis container for sortable column headers.
          // It makes sure that the direction icon is always visible.
          div.ellipsis {
            display: flex;
            min-width: 0;
            align-items: center;
            & > label {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            & > p-sorticon {
              flex-shrink: 0;
            }
          }
          &.p-highlight {
            background-color: $color-text-lighter;
          }
        }
      }
    }
  }
  tbody {
    & > tr {
      &:nth-child(even) {
        background-color: $color-lightgray-3;
      }
      &:nth-child(odd) {
        background-color: $color-white;
      }
      &:nth-child(odd):last-child {
        border-bottom: 1px solid $color-background-3;
      }
      & > td {
        padding: 12px;
        vertical-align: middle;
        overflow-wrap: break-word;
        .p-column-title {
          display: none;
        }
        .ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .flex-cell {
          display: flex;
          align-items: center;

          > * {
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
        & > .expanded-row-container {
          > * .p-paginator {
            background: inherit;
          }
        }
      }
    }
  }
  // loader
  .p-datatable-loading-overlay {
    background-color: rgba(0, 0, 0, 0.25);
  }

  &.narrow {
    table {
      display: block;
      thead {
        display: none;
      }
      tbody {
        display: grid;
        grid-template-columns: max-content auto;
        & > tr {
          display: contents;
          &:nth-child(even) > * > * {
            background-color: $color-lightgray-3;
          }
          & > td {
            display: contents;
            & > div {
              // fill whole cell with background color
              align-self: stretch;
              // center content
              padding: 0.2em 0;
              // break long words when ellipsis is off
              word-break: break-word;
            }
            .p-column-title {
              align-self: stretch;
              display: inline-block;
              padding: 0.2em 12px;
              font-weight: bold;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

// ================= SCROLLPANEL

p-scrollPanel {
  &.hide-x-scroll {
    .p-scrollpanel-bar-x {
      display: none;
    }
  }

  &.prevent-scroll {
    .p-scrollpanel-bar-x,
    .p-scrollpanel-bar-y {
      display: none;
    }
    .p-scrollpanel-content {
      overflow-y: hidden;
    }
  }
}

// ================= BUTTON

.p-button {
  margin: 0;
  border-width: 1px;
  border-style: solid;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 46px;
  height: 46px;
  min-width: 46px;
  margin: calc(0.571429rem) 0px;
  outline: 0px;
  padding: 0px calc(1.07143rem);
  overflow: hidden;
  position: relative;
  &:disabled {
    cursor: default;
  }
  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0px;
  }
  .p-button-label {
    padding: 0.429em 1em;
    transition: none; // do not wiggle when expanding row of table
  }
  &.p-button-icon-only {
    font-size: 24px;
    padding: 0;
    margin: 0;
    .p-button-label {
      padding: 0.429em;
    }
  }
}
.p-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  &.p-button-icon-left {
    left: initial;
    & ~ .p-button-label {
      padding-left: 2em;
    }
  }
}

body .p-button {
  color: $color-outline;
  background-color: $color-outline-light;
  border-color: $color-outline-light;
  &:not(.p-disabled):not([disabled]) {
    color: $color-primary-fg;
    background-color: $color-primary;
    border-color: $color-primary;
    &:focus,
    &:hover {
      color: $color-primary-fg;
      background-color: $color-primary-light;
      border-color: $color-primary-light;
    }
    &:active {
      color: $color-primary-fg;
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
    }
  }
  &.ui-button-secondary {
    color: $color-text-disabled;
    background-color: $color-bg;
    border-color: $color-text-disabled;
    &:enabled {
      color: $color-primary;
      background-color: $color-bg;
      border-color: $color-primary;
      &:focus,
      &:hover {
        color: $color-primary-fg;
        background-color: $color-primary-light;
        border-color: $color-primary-light;
      }
      &:active {
        color: $color-primary-fg;
        background-color: $color-primary-dark;
        border-color: $color-primary-dark;
      }
    }
  }
  &.ui-button-bare {
    color: $color-text-disabled;
    background-color: transparent;
    border-color: transparent;
    &:enabled {
      color: $color-primary;
      background-color: transparent;
      border-color: transparent;
      &:focus,
      &:hover {
        color: $color-primary-light;
        background-color: transparent;
        border-color: transparent;
      }
      &:active {
        color: $color-primary-dark;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
  &.ui-button-bare-neutral {
    color: $color-text-disabled;
    background-color: transparent;
    border-color: transparent;
    &:enabled {
      color: $color-text-light;
      background-color: transparent;
      border-color: transparent;
      &:focus,
      &:hover {
        color: $color-text-lighter;
        background-color: transparent;
        border-color: transparent;
      }
      &:active {
        color: $color-text;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

// INPUT
.p-inputtext {
  border: solid 1px $color-midgray-1;
  color: $color-text;
  padding: 12px 16px;
  text-indent: 0;
  height: 46px;
  overflow: auto;

  &:focus {
    outline: none;
    border-color: $color-primary;
  }

  &:disabled {
    color: $color-text-disabled;
    border-color: $color-midgray-3;
  }

  // See https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px $color-white inset;
    -webkit-text-fill-color: $color-text;
  }
}

.p-inputtext.bad-input {
  border-color: $color-error;
  position: relative;
  & ~ .error-icon {
    visibility: visible;
  }
  & ~ label {
    color: $color-error !important;
  }
}

.error-icon {
  visibility: hidden;
  color: $color-error;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

.p-float-label {
  display: inline-block;
  > label {
    background-color: $color-white;
    color: $color-text-lighter;
    margin-left: 8px;
    white-space: nowrap;
    left: 10px;
    top: 50%;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }
  & > input:disabled ~ label,
  & > textarea:disabled ~ label {
    color: $color-text-disabled;
  }
  & > input:focus ~ label,
  & > textarea:focus ~ label,
  & > .p-inputwrapper-focus ~ label {
    color: $color-primary;
  }
}

.p-float-label {
  & > input:required ~ label::after,
  & > textarea:required ~ label::after,
  & > .ui-required ~ label::after {
    content: ' *';
  }
}

.p-float-label textarea ~ label {
  margin-top: 0;
}

.p-float-label > input:focus ~ label,
.p-float-label > input.p-filled ~ label,
.p-float-label > textarea:focus ~ label,
.p-float-label > textarea.p-filled ~ label,
.p-float-label > .p-inputwrapper-focus ~ label,
.p-float-label > .p-inputwrapper-filled ~ label,
.p-float-label > input:placeholder-shown ~ label,
.p-float-label > textarea:placeholder-shown ~ label {
  top: 0;
  left: 0;
  font-size: 12px;
  padding: 0px 8px;
  margin-top: -0.5em;
}

// DIALOG
.p-dialog {
  box-shadow: 1px 2px 5px $color-midgray-2;
  min-width: 25%;
  max-width: 38em;

  .p-dialog-header {
    color: $color-darkgray-3;
    background: $color-white;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    padding: 24px 24px 16px;
  }

  .p-dialog-title {
    margin-right: 8px;
  }

  .p-dialog-header-icon {
    padding: 0;
    color: $color-darkgray-2;
    &:hover {
      color: $color-midgray-2;
    }
  }

  .p-dialog-content {
    padding: 0em 24px 24px;
    background: $color-white;
  }

  .p-dialog-footer {
    border: 0 none;
    padding-top: 1em;
    padding: 0;
    background: $color-white;

    p-footer {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 0 24px 24px;

      button {
        min-width: 140px;
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }
}

.p-dialog-mask {
  &.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
  }
  & > .p-dialog {
    box-shadow: none;
  }
}

// TEXTAREA
.p-inputtextarea {
  resize: none;
  height: auto;
  background: transparent;

  &:focus {
    outline: none;
    border-color: $color-primary;
  }

  &:disabled {
    color: $color-text-disabled;
    border-color: $color-midgray-3;
  }
}

.p-inputtextarea::-webkit-scrollbar {
  width: 18px;
  background: $color-white;
}
.p-inputtextarea::-webkit-scrollbar-thumb {
  border: 6px solid $color-white;
  border-radius: 10px;
  background: $color-midgray-2;
}
.p-inputtextarea::-webkit-scrollbar-thumb:hover {
  background: $color-darkgray-4;
}
// Scrollbar workaround for Firefox
.p-inputtextarea {
  scrollbar-color: $color-midgray-2 $color-white;
}

.p-progressbar {
  height: 1.5em;
  background: $color-midgray-3;
}

.p-progressbar.p-progressbar-determinate,
.p-progressbar.p-progressbar-indeterminate {
  height: 1.5em;
  .p-progressbar-label {
    font-size: 9pt;
    color: $color-text-dark;
  }
  .p-progressbar-value {
    background: $color-primary-light;
  }

  .p-progressbar-value-animate {
    transition: none;
  }
}

// do not show progress bar in fileupload component
.p-fileupload {
  .p-progressbar {
    display: none;
  }
}

.p-tooltip.p-tooltip-right,
.p-tooltip.p-tooltip-left,
.p-tooltip.p-tooltip-top,
.p-tooltip.p-tooltip-bottom {
  padding: 0;
}

.p-tooltip.p-tooltip-right {
  margin-left: 10px;
}
.p-tooltip.p-tooltip-left {
  margin-right: 10px;
}
.p-tooltip.p-tooltip-top {
  margin-bottom: 10px;
}
.p-tooltip.p-tooltip-bottom {
  margin-top: 10px;
}

.p-tooltip {
  border: 1px solid $color-midgray-1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  line-height: 1.6;
  background-color: white;
  &.version-tooltip {
    width: 33%;
    max-width: 550px;
    // Media query mainly used for IPads and other tables having smaller resolutions
    height: 75%;
    max-height: 675px;

    @media (min-height: 1050px) {
      height: auto;
      max-height: auto;
    }
  }
}
.p-tooltip:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: -1;
}
.p-tooltip.p-tooltip-right:before {
  bottom: calc(50% - 4px);
  left: -4px;
  border-left: 1px solid $color-midgray-1;
  border-bottom: 1px solid $color-midgray-1;
}
.p-tooltip.p-tooltip-left:before {
  bottom: calc(50% - 4px);
  right: -4px;
  border-right: 1px solid $color-midgray-1;
  border-top: 1px solid $color-midgray-1;
}
.p-tooltip.p-tooltip-bottom:before {
  left: calc(50% - 4px);
  top: -4px;
  border-left: 1px solid $color-midgray-1;
  border-top: 1px solid $color-midgray-1;
}
.p-tooltip.p-tooltip-top:before {
  left: calc(50% - 4px);
  bottom: -4px;
  border-right: 1px solid $color-midgray-1;
  border-bottom: 1px solid $color-midgray-1;
}
.p-tooltip .p-tooltip-text {
  background-color: white;
  color: $color-darkgray-2;
  padding: 24px;
  overflow: auto;
  height: 100%;
}
.p-tooltip .p-tooltip-text.p-shadow {
  box-shadow: none;
}
// disable the standard arrow
.p-tooltip-right .p-tooltip-arrow {
  border: none;
}
.p-tooltip-left .p-tooltip-arrow {
  border: none;
}
.p-tooltip-top .p-tooltip-arrow {
  border: none;
}
.p-tooltip-bottom .p-tooltip-arrow {
  border: none;
}

// ================= TOAST

.p-toast {
  .p-toast-message {
    box-shadow: 1px 2px 5px 0 $color-midgray-2;
    border-left: 4px solid;
    margin: 0 0 1em 0;
    .p-toast-message-content {
      margin: 16px 12px;
      .p-toast-message-icon {
        font-size: 24px;
      }
      .p-toast-message-text {
        margin-left: 8px;
        margin-right: 16px;
        .p-toast-summary {
          line-height: 24px;
          font-weight: 700;
        }
      }
      .p-toast-icon-close .pi {
        font-size: 18px;
      }
    }
  }
}

body .p-toast {
  .p-toast-message {
    color: $color-text;
    .p-toast-close-icon {
      color: $color-text;
    }
    &.p-toast-message-error {
      background-color: $color-error-light;
      border-color: $color-error;
    }
    &.p-toast-message-warn {
      background-color: $color-warning-light;
      border-color: $color-warning;
    }
    &.p-toast-message-info {
      background-color: $color-information-light;
      border-color: $color-primary;
    }
    &.p-toast-message-success {
      background-color: $color-success-light;
      border-color: $color-success;
    }
  }
}

// ================= LISTBOX

p-listbox {
  .p-listbox {
    border: 1px solid $color-outline;
    .p-listbox-item,
    .p-listbox-header {
      height: 36px;
      padding: 6px 16px;
      .p-checkbox {
        .p-checkbox-box {
          width: 24px;
          height: 24px;
          border: 1px solid $color-outline;
          border-radius: 0px;
          background-color: $color-white;
          color: $color-primary;
        }
      }
    }
    .p-listbox-item {
      .p-checkbox {
        padding: 0 8px 0 0;
      }
      &.p-highlight {
        color: $color-text;
        background-color: $color-neutral-50;
        border-left: 6px solid $color-primary-light;
        padding-left: 10px;
      }
      &:focus:not(:active) {
        box-shadow: none;
        outline: 1px solid $color-primary;
        outline-offset: -1px;
        .p-checkbox .p-checkbox-box {
          border: 1px solid $color-primary;
          background-color: $color-primary-3;
        }
      }
      &:focus:not(:focus-visible) {
        outline: none;
        .p-checkbox .p-checkbox-box {
          border: 1px solid $color-outline;
          background-color: $color-background;
        }
      }
      // 'not' makes this rule as specific as focus rule above.
      // Otherwise hovering over the most recently clicked row
      // does not change the border of the checkbox.
      &:hover:not(.p-disabled) {
        background-color: $color-neutral-50;
        .p-checkbox .p-checkbox-box {
          border: 1px solid $color-primary;
          background-color: $color-background;
        }
      }
      &.p-disabled {
        color: $color-text-disabled;
        &.p-highlight {
          background-color: $color-background;
          border-left: 6px solid $color-outline-light;
        }
        .p-checkbox .p-checkbox-box {
          border: 1px solid $color-outline-light;
          color: $color-outline-light;
        }
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .p-listbox-header {
      display: flex;
      align-items: center;
      gap: 8px;
      border-bottom: 1px solid $color-outline;
      .p-checkbox {
        .p-checkbox-box.p-focus {
          border: 1px solid $color-primary;
          background-color: $color-primary-3;
        }
        &:hover {
          .p-checkbox-box {
            border: 1px solid $color-primary;
            background-color: $color-background;
          }
        }
      }
      .p-listbox-filter-container {
        display: flex;
        flex-grow: 1;
        height: 24px;

        .p-listbox-filter {
          flex-grow: 1;
          height: 24px;
          padding: 0px;
          margin: 0px 8px 0px 0px;
        }

        .p-listbox-filter-icon {
          height: 24px;
          width: 24px;
          margin: 0px;
          position: static;
        }
      }
    }
  }
}

// ================= DROPDOWN

.p-dropdown {
  position: relative;
  width: 100%;
  min-width: 0;
  height: 46px;
  display: block;
  border: thin solid $color-midgray-1;

  p-overlay {
    z-index: 9;
    > .p-overlay.p-component {
      max-width: 100%;
      /* !important is needed because the min-width is set automatically as inline style by primeng to a static value */
      min-width: 100% !important;
    }
  }

  &:not(.p-disabled):hover {
    background-color: $color-white;
    box-shadow: none;
  }

  &:not(.p-disabled) {
    background-color: $color-white;
    box-shadow: none;

    &.p-dropdown-open {
      border: thin solid $color-primary;

      .p-dropdown-trigger {
        color: $color-primary;
      }
    }
  }

  &.p-disabled {
    border-color: $color-outline-light;

    .p-dropdown-label {
      color: $color-text-disabled;
    }

    .p-dropdown-trigger {
      color: $color-outline-light;
    }
  }

  .p-dropdown-label {
    height: 35px;
    line-height: 35px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 16px;
    margin-top: 4px;
    margin-right: 10px;
    border: none;

    display: flex;
    align-items: center;
    > * {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }

    span,
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      color: $color-text;
    }
  }

  .p-dropdown-trigger {
    width: 40px;
    height: 40px;
    margin: 3px;
    color: $color-darkgray-4;
    border: none;
  }

  .p-dropdown-panel {
    background: $color-white;
    border: thin solid;
    margin-top: 0.25em;
    border-color: $color-midgray-1;
    box-shadow: none;
    width: 100%;
  }

  .p-dropdown-items {
    padding: 0;
  }

  .p-dropdown-item {
    margin: 0;
    padding: 9px 16px;
    color: $color-darkgray-2;
    background: transparent;

    display: flex;
    align-items: center;
    > * {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }

    &.p-highlight {
      background-color: $color-lightgray-1;
      border-left: 6px solid $color-primary-light;
      padding-left: 10px;
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      background-color: $color-background-3;
    }

    span,
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }

  .p-dropdown-empty-message {
    margin: 0;
    padding: 9px 16px;
  }

  .p-dropdown-item-empty {
    text-indent: 0;
    padding: 0;
  }

  .p-placeholder {
    color: $color-text-lighter;
    opacity: 1;
  }

  .p-dropdown-filter-icon {
    visibility: hidden;
  }
}

body .pi.disabled {
  color: $color-text-disabled;
}

// ================= TABMENU

p-tabmenu {
  display: block;

  .p-tabmenu .p-tabmenu-nav {
    .p-tabmenuitem {
      background-color: $color-midgray-3;

      &:not(.p-highlight):not(.p-disabled):hover {
        background-color: $color-lightgray-1;
      }

      &:not(.p-highlight):not(.p-disabled) {
        .p-menuitem-link:focus {
          background-color: $color-lightgray-2;
        }
      }

      &.p-highlight {
        background-color: $color-white;
      }

      .p-menuitem-link {
        color: $color-darkgray-1;
      }
    }
  }
}

.navigation-tabs-style {
  .p-tabmenu {
    .p-tabmenu-nav {
      background-color: $color-lightgray-1;
      overflow: hidden;

      .p-tabmenuitem {
        background-color: $color-lightgray-1;
        align-items: flex-start;

        &:active {
          background-color: $color-lightgray-1;
        }

        &.p-highlight {
          background-color: $color-lightgray-1;

          .p-menuitem-text {
            border-bottom-color: $color-primary;
            border-bottom-style: solid;
            border-bottom-width: 3px;
            font-weight: bold;
            color: $color-primary;
          }
        }

        // width: 100%;
        border: none;
        font-size: 1rem;
        line-height: 1.75rem;
        white-space: nowrap;

        &:not(:last-child) {
          margin-right: 1px;
        }

        .p-menuitem-link {
          padding: 0 1.25rem;
          width: 100%;
          outline: none;
          justify-content: center;
        }

        .p-menuitem-text {
          margin: 0px 0.5rem;
          line-height: 2.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;
          color: $color-darkgray-3;
        }
      }
    }
  }
}

// TABMENU SDS CLASSIC/CONTAINER STYLE
.classic-tabs-style {
  .p-tabmenu {
    // labels
    .p-tabmenu-nav {
      overflow: hidden;
      // headline
      .p-tabmenuitem {
        width: 100%;
        border: none;
        font-size: 1rem;
        line-height: 1.75rem;
        white-space: nowrap;

        &:not(:last-child) {
          margin-right: 1px;
        }

        .p-menuitem-link {
          padding: 0 1.25rem;
          width: 100%;
          outline: none;
          justify-content: center;
        }

        .p-menuitem-text {
          margin: 0px 0.5rem;
          line-height: 2.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

// ================= MULTISELECT

p-multiselect {
  &.abbrev-drop-down {
    .p-multiselect-panel {
      width: 100%;
      max-width: fit-content;
      z-index: 100;
    }
    .p-multiselect-item {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .p-multiselect {
    height: 46px;
    align-items: center;

    .p-multiselected-item-token.p-corner-all.ng-star-inserted {
      background: $color-lightgray-1;
      display: inline-block;
      margin: 2px;
      padding: 2px;
      color: $color-darkgray-3 img {
        margin-right: 4px;
      }

      .pi-caret-down:before {
        color: $color-primary;
      }
    }

    width: 100%;
    border: thin solid $color-midgray-1;
    color: $color-darkgray-2;

    .p-multiselect-label {
      background-color: #ffffff;
      white-space: nowrap;
    }

    .p-multiselect-label.p-multiselect-label-empty {
      visibility: hidden;
    }

    &:not(.p-disabled) {
      background-color: $color-white;
      box-shadow: none;

      &.p-multiselect-open {
        border: thin solid $color-primary;

        .p-multiselect-trigger {
          color: $color-primary;
        }
      }
    }

    .p-multiselect-trigger {
      color: $color-darkgray-4;
      margin-right: 1em;
    }

    .p-multiselect-label-container {
      text-align: left;
      padding-left: 1em;
      background-color: $color-white;
    }

    .p-multiselect-items-wrapper {
      overflow-x: hidden !important;
    }

    &.p-disabled {
      color: $color-text-disabled;
      border-color: $color-outline-light;
      .p-multiselect-trigger {
        color: $color-outline-light;
      }
    }
  }

  .p-focus {
    background-color: $color-white;
  }

  .p-highlight {
    background-color: $color-white;
    color: inherit;
  }
}

.p-multiselect-panel {
  z-index: 100;
  background-color: $color-white;
  border: thin solid;
  margin-top: 4px;
  border-color: $color-midgray-1;
  box-shadow: none;

  .p-multiselect-header {
    margin: 6px;
    div.p-checkbox {
      display: none;
    }
    .p-multiselect-filter-container {
      input.p-multiselect-filter.p-inputtext {
        padding-left: 48px;
      }
      span.p-multiselect-filter-icon {
        position: absolute;
        left: 23px;
        top: 12px;
        margin-top: 0;
      }
    }

    button.p-multiselect-close {
      display: none;
    }
  }

  .p-multiselect-items-wrapper {
    margin: 6px 0;
  }

  .p-multiselect-footer {
    margin: 6px 0;
  }

  .p-multiselect-close {
    color: $headerIconTextColor;
  }

  .p-multiselect-filter-container .fa {
    color: $inputTextColor;
  }
}

.p-multiselect-item {
  height: 46px;
  padding: 6px 16px;

  &.p-highlight {
    color: $color-text;
    background-color: $color-neutral-50;
    border-left: 6px solid $color-primary-light;
    padding-left: 10px;
  }
  &:hover {
    background-color: $color-neutral-50;
  }

  // label has no class
  & > span {
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include checkbox-box;
}

.p-multiselect-empty-message {
  padding: 6px 16px;
}

// ================= CHECKBOX

@mixin checkbox-box {
  .p-checkbox {
    .p-checkbox-box {
      color: $color-primary;
      background-color: $color-background;
      border: 1px solid $color-outline;
      border-radius: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;

      checkicon::before {
        font-size: 22px;
      }
    }
  }
  .p-checkbox.p-checkbox-focused,
  .p-checkbox:hover {
    .p-checkbox-box {
      border-color: $color-primary;
    }
  }
  .p-checkbox.p-checkbox-disabled {
    .p-checkbox-box {
      border-color: $color-outline-light;
      checkicon::before {
        color: $color-outline-light;
      }
    }
  }
}

p-checkbox {
  margin: 13px 0px;

  @include checkbox-box;

  .p-checkbox-label {
    padding-left: 8px;
    line-height: 24px;
    cursor: pointer;

    &.p-disabled {
      cursor: default;
      color: $color-text-disabled;
    }
  }

  &.error {
    .p-checkbox .p-checkbox-box {
      border-color: $color-error;
      checkicon::before {
        color: $color-error;
      }
    }
    .p-checkbox.p-checkbox-focused,
    .p-checkbox:hover {
      .p-checkbox-box {
        border-color: $color-primary;
      }
    }
  }
}

// ICONS
.pi {
  font-size: 24px;

  &.pi-large {
    font-size: 28px;
  }

  &.pi-small {
    font-size: 18px;
  }

  &.pi-globe {
    font-family: 'Material Icons';
    &:before {
      content: '\e80b';
    }
  }
  &.pi-user {
    font-family: 'Material Icons';
    &:before {
      content: '\e7fd';
    }
  }
  &.pi-download {
    font-family: 'Material Icons';
    &::before {
      content: '\e2c4';
    }
  }
  &.pi-delete {
    font-family: 'Material Icons';
    &::before {
      content: '\e872';
    }
  }
  &.pi-times {
    font-family: 'Material Icons';
    &::before {
      content: '\e5cd';
    }
  }
  &.pi-plus {
    font-family: 'Material Icons';
    &::before {
      content: '\e145';
    }
  }

  &.pi-search {
    font-family: 'Material Icons';
    &::before {
      content: '\e8b6';
    }
  }

  &.pi-sort-alt {
    &:before {
      content: '';
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eactions / sort_default%3C/title%3E%3Cg id='Module' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Product-page_APPLICATIONS' transform='translate(-1137.000000, -423.000000)'%3E%3Cg id='actions-/-sort_default' transform='translate(1134.000000, 420.000000)'%3E%3Cpolygon id='Shape' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpath d='M7.5110652,3 L12,8 L10.9760698,8.90336214 L8.19345721,5.80585638 L8.19345721,21 L6.82456652,21 L6.82456652,5.79295121 L4.01845467,9 L3,8.09256254 L7.5110652,3 Z M17.1934572,3 L17.1934572,18.1941436 L19.9760698,15.0966379 L21,16 L16.5110652,21 L12,15.9074375 L13.0184547,15 L15.8245665,18.2070488 L15.8245665,3 L17.1934572,3 Z' id='sort_default' fill='%23737F85' transform='translate(12.000000, 12.000000) rotate(-360.000000) translate(-12.000000, -12.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      height: 18px;
      width: 18px;
    }
  }
  &.pi-sort-amount-down {
    &:before {
      content: '';
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg width='12px' height='18px' viewBox='0 0 12 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eactions / sort_downwards%3C/title%3E%3Cg id='Module' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Product-page_APPLICATIONS' transform='translate(-1260.000000, -426.000000)'%3E%3Cg id='actions-/-sort_downwards' transform='translate(1254.000000, 423.000000)'%3E%3Cpolygon id='Shape' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpolygon id='sort_downwards' fill='%23fff' transform='translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) ' points='6 9.62035395 7.02080951 10.5277914 11.3126 5.79295121 11.3126 21 12.6846558 21 12.6846558 5.80585638 16.9737023 10.5841666 18 9.6808045 12.000686 3'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      height: 18px;
      width: 18px;
    }
  }
  &.pi-sort-amount-up-alt {
    &:before {
      content: '';
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg width='12px' height='18px' viewBox='0 0 12 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eactions / sort_upwards%3C/title%3E%3Cg id='Module' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Product-page_APPLICATIONS' transform='translate(-1350.000000, -430.000000)'%3E%3Cg id='actions-/-sort_upwards' transform='translate(1344.000000, 427.000000)'%3E%3Cpolygon id='Shape' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpolygon id='sort_upwards' fill='%23fff' transform='translate(12.000000, 12.000000) rotate(-360.000000) translate(-12.000000, -12.000000) ' points='6 9.62035395 7.02080951 10.5277914 11.3126 5.79295121 11.3126 21 12.6846558 21 12.6846558 5.80585638 16.9737023 10.5841666 18 9.6808045 12.000686 3'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      height: 18px;
      width: 18px;
    }
  }

  &.pi-nosearch {
    font-family: 'Material Icons';
    &::before {
      content: '\ea76';
    }
  }

  &.pi-content-copy {
    font-family: 'Material Icons';
    &::before {
      content: '\e14d';
    }
  }

  &.pi-no-sim {
    font-family: 'Material Icons';
    &::before {
      content: '\e0cc';
    }
  }

  &.pi-zoom-out {
    font-family: 'Material Icons';
    &::before {
      content: '\e900';
    }
  }

  &.pi-group {
    font-family: 'Material Icons';
    &::before {
      content: '\f233';
    }
  }

  &.pi-check {
    font-family: 'Material Icons';
    &:before {
      content: '\e876';
    }
  }

  &.pi-chevron-down {
    font-family: 'Material Icons';
    &:before {
      content: '\e5cf';
    }
  }
  &.pi-chevron-left {
    font-family: 'Material Icons';
    &:before {
      content: '\e5cb';
    }
  }
  &.pi-chevron-right {
    font-family: 'Material Icons';
    &:before {
      content: '\e5cc';
    }
  }
  &.pi-edit {
    font-family: 'Material Icons';
    &:before {
      content: '\e3c9';
    }
  }
  &.pi-apps {
    font-family: 'Material Icons';
    &:before {
      content: '\e5c3';
    }
  }
  &.pi-radio-button-checked {
    font-family: 'Material Icons';
    &:before {
      content: '\e837';
    }
  }
}
