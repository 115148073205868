// Official SICK design colors
// Please see https://extranet.sick.com/display/SDS/Color for reference and description

// Official color palette
$color-primary-600: #005b8e;
$color-primary-500: #007cc1;
$color-primary-400: #009df4;
$color-primary-10: #bce7ff;
$color-primary-3: #e5f6ff;

$color-accent-600: #e36a00;
$color-accent-500: #f39200;
$color-accent-400: #ffa927;
$color-error-500: #ea0823;
$color-error-20: #fee9eb;
$color-warning-500: #f5c413;
$color-warning-100: #fdf5d7;
$color-success-500: #63b017;
$color-success-10: #e0f8c9;
$color-success-5: #f0fbe4;

$color-neutral-900: #141718;
$color-neutral-800: #2c3133;
$color-neutral-650: #50585c;
$color-neutral-500: #737f85;
$color-neutral-400: #8e989d;
$color-neutral-300: #b1b8bc;
$color-neutral-200: #c5cacc;
$color-neutral-100: #e0e3e4;
$color-neutral-50: #eeeff0;
$color-neutral-20: #f6f7f7;
$color-neutral-1: #fbfcfc;
$color-neutral-0: #ffffff;

// Brand colors
$color-primary-fg: $color-neutral-0;
$color-primary: $color-primary-500;
$color-primary-light: $color-primary-400;
$color-primary-dark: $color-primary-600;

$color-bg: $color-neutral-0;

$color-secondary: $color-neutral-500;
$color-secondary-light: $color-neutral-400;
$color-secondary-dark: $color-neutral-650;

$color-accent: $color-accent-500;
$color-accent-light: $color-accent-400;
$color-accent-dark: $color-accent-600;

// UI and neutral colors
$color-darkgray-1: $color-neutral-900;
$color-text-dark: $color-darkgray-1;
$color-darkgray-2: $color-neutral-800;
$color-text: $color-darkgray-2;
$color-darkgray-3: $color-neutral-650;
$color-text-light: $color-darkgray-3;
$color-darkgray-4: $color-neutral-500;
$color-text-lighter: $color-darkgray-4;
$color-midgray-1: $color-neutral-400;
$color-outline: $color-midgray-1;
$color-midgray-2: $color-neutral-300;
$color-text-disabled: $color-midgray-2;
$color-midgray-3: $color-neutral-200;
$color-outline-light: $color-midgray-3;
$color-lightgray-3: $color-neutral-20;
$color-lightgray-2: $color-neutral-1;
$color-background-2: $color-lightgray-2;
$color-lightgray-1: $color-neutral-50;
$color-background-3: $color-lightgray-1;
$color-white: $color-neutral-0;
$color-background: $color-white;

// Status colors
$color-error: $color-error-500;
$color-error-light: $color-error-20;
$color-success: $color-success-500;
$color-success-light: $color-success-10;
$color-warning: $color-warning-500;
$color-warning-light: $color-warning-100;
$color-information: $color-primary-500;
$color-information-light: $color-primary-10;

// callouts
$color-callout-info: #e5f1f8;
$color-callout-error: #fce6e9;
$color-callout-warn: #fef9e7;
$color-callout-success: #eff7e7;

// Custom - non official SICK color codes
$color-custom-light-blue: #c5e9fc;
$color-custom-tablerow-expanded: #f6f7f95e;
