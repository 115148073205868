@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Open Sans Regular'), local('OpenSans-Regular'),
    url(https://cdn.sick.com/media/fonts/opensans-v1/Regular/OpenSans-Regular.woff2) format('woff2'),
    url(https://cdn.sick.com/media/fonts/opensans-v1/Regular/OpenSans-Regular.woff) format('woff'),
    url(https://cdn.sick.com/media/fonts/opensans-v1/Regular/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url(https://cdn.sick.com/media/fonts/opensans-v1/SemiBold/OpenSans-SemiBold.woff2) format('woff2'),
    url(https://cdn.sick.com/media/fonts/opensans-v1/SemiBold/OpenSans-SemiBold.woff) format('woff'),
    url(https://cdn.sick.com/media/fonts/opensans-v1/SemiBold/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url(https://cdn.sick.com/media/fonts/opensans-v1/Bold/OpenSans-Bold.woff2) format('woff2'),
    url(https://cdn.sick.com/media/fonts/opensans-v1/Bold/OpenSans-Bold.woff) format('woff'),
    url(https://cdn.sick.com/media/fonts/opensans-v1/Bold/OpenSans-Bold.ttf) format('truetype');
}

h1 {
  font-size: 34.84px;
  line-height: 1.2;
  font-weight: 400;
}
h2 {
  font-size: 29.03px;
  line-height: 1.2;
  font-weight: 400;
}
h3 {
  font-size: 24.19px;
  line-height: 1.3;
  font-weight: 400;
}
h4 {
  font-size: 20.16px;
  line-height: 1.3;
  font-weight: 600;
}
h5 {
  font-size: 16.8px;
  line-height: 1.4;
  font-weight: 700;
}
h6 {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 700;
}
p {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
}
p.s {
  font-size: 11px;
  line-height: 1.4;
  font-weight: 400;
}
p.xs {
  font-size: 10px;
  line-height: 1.4;
  font-weight: 400;
}
